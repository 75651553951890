/**
 * Copyright 2023 Google LLC
 */

export const inputOptionSpec = () => {
  return [
    {
      value: 'forward',
      label: 'Forward',
      unitValue: 'cm',
      isVariantValue: true,
    },
    {
      value: 'backward',
      label: 'Backward',
      unitValue: 'cm',
      isVariantValue: true,
    },
    {
      value: 'up',
      label: 'Up',
      unitValue: 'cm',
      isVariantValue: true,
    },
    {
      value: 'down',
      label: 'Down',
      unitValue: 'cm',
      isVariantValue: true,
    },
    {
      value: 'cw',
      label: 'Right',
      unitValue: '°',
      isVariantValue: true,
    },
    {
      value: 'ccw',
      label: 'Left',
      unitValue: '°',
      isVariantValue: true,
    },
    {
      value: 'land',
      label: 'Land',
      unitValue: '',
      isVariantValue: false,
    },
  ];
};
