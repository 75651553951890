/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import FlightIcon from '@mui/icons-material/Flight';
import { UserAuth } from '../../contexts/AuthContext';
import postCaptureMode from '../../requests/postCaptureMode';
import addDocument from '../../firebase/addDocument';
import { BaseModelPath } from '../../requests/constants';
import getSharedModels from '../../firebase/getSharedModels';
import { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import getDocument from '../../firebase/getDocument';

const RunContainer = () => {
  const { token } = UserAuth();
  const [modelListLoading, setIsModelListLoading] = useState(true);
  const [models, setModels] = useState<string[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>('');

  type ModelSelectionState = {
    model: string;
  };

  // Get a list of models from the Shared Models GCS Bucket
  useEffect(() => {
    const fetchModels = async () => {
      try {
        setIsModelListLoading(true);
        const modelsList = await getSharedModels();
        setModels(modelsList);
        setIsModelListLoading(false);
      } catch (error) {
        console.error('Error fetching shared models:', error);
        setIsModelListLoading(false);
      }
    };

    fetchModels();
  }, []);

  const handleSelectionChange = (event: SelectChangeEvent<string>) => {
    setSelectedModel(event.target.value);
    addDocument('UIState', 'model_state', {
      model: event.target.value,
    });
  };

  // Get a list of models from the Shared Models GCS Bucket
  useEffect(() => {
    console.log('selected model changed ' + selectedModel);
  }, [selectedModel]);

  // If the Model List has loaded - Check if we have a model previously used which is stored in Firestore
  useEffect(() => {
    if (!modelListLoading) {
      const handleDataFetched = (data: ModelSelectionState) => {
        if (data && data.model != '' && selectedModel == '') {
          if (models.includes(data.model)) {
            setSelectedModel(data.model);
          }
        }
      };

      getDocument(handleDataFetched, 'UIState', 'model_state');
    }
  }, [modelListLoading]);

  const handleCaptureChange = () => {
    if (selectedModel == '') {
      alert('You must select a model');
    } else {
      const mode = 'flight_control';
      addDocument('ImageCapture', 'captureState', {
        enabled: false,
      });
      postCaptureMode(mode, BaseModelPath + selectedModel, token);
    }
  };

  return (
    <Box className="flex flex-col gap-2">
      <FormControl fullWidth className="pb-1">
        <InputLabel id="model-select-label">Select a Model</InputLabel>
        <Select
          labelId="model-select-label"
          value={selectedModel}
          onChange={handleSelectionChange}
          label="Select a Model"
        >
          {models.map((model, index) => (
            <MenuItem key={index} value={model}>
              {model}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        className="h-16 text-center"
        variant="contained"
        color="primary"
        onClick={handleCaptureChange}
      >
        <FlightIcon className="mr-2" /> Enable Flight Mode
      </Button>
    </Box>
  );
};

export default RunContainer;
