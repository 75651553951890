/**
 * Copyright 2023 Google LLC
 */

import { Box, CircularProgress, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import FlightIcon from '@mui/icons-material/Flight';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import getCollectionOrderByTimeLimitOne from '../../firebase/getCollectionOrderByTimeLimitOne';
import { FlightControlState } from '../../contexts/FlightControlStateContext';

const FlightMode = () => {
  const [droneMode, setDroneMode] = useState<any>('');
  const [droneState, setDroneState] = useState<any>('');
  const [DCUState, setDCUState] = useState<any>('');
  const [refreshKey, setRefreshKey] = useState(0);
  const { droneFlightState } = FlightControlState();

  useEffect(() => {
    getCollectionOrderByTimeLimitOne(setDroneMode, 'ImageCapture');
    getCollectionOrderByTimeLimitOne(setDroneState, 'OS-TELEM-firestore');
  }, []);

  useEffect(() => {
    getCollectionOrderByTimeLimitOne(setDCUState, 'OS-TELEM-firestore');

    const intervalId = setInterval(() => {
      setRefreshKey((prevKey) => prevKey + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (!moment(DCUState?.publishTime?.toDate()).fromNow().includes('a few seconds ago')) {
    return (
      <Box
        className="flex flex-col items-center justify-center"
        sx={{ width: 96, height: 48 }}
        key={refreshKey}
      >
        <Box>
          <Typography variant="body2" sx={{ height: 20 }}>
            Drone Mode
          </Typography>
        </Box>
        <Box className="flex items-center justify-center" sx={{ width: 20, height: 20 }}>
          <CircleIcon color="error" fontSize="small" />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box className="flex flex-col items-center justify-center" sx={{ width: 96, height: 48 }}>
        <Box>
          <Typography variant="body2" sx={{ height: 20 }}>
            Drone Mode
          </Typography>
        </Box>
        <Box className="flex items-center justify-center" sx={{ width: 20, height: 20 }}>
          {droneState?.payload?.secondaryNICStatus?.connectionStatus == '100 (connected)' ? (
            droneMode?.enabled ? (
              <PhotoCameraIcon fontSize="small" />
            ) : droneFlightState == 'ready_for_commands' ? (
              <FlightIcon color="success" fontSize="small" />
            ) : droneFlightState == 'video_ready' || droneFlightState == 'tello_connected' ? (
              <CircularProgress size="1rem" />
            ) : (
              <CircleIcon color="error" fontSize="small" />
            )
          ) : (
            droneState?.payload?.secondaryNICStatus?.connectionStatus == '30 (disconnected)' && (
              <CircleIcon color="error" fontSize="small" />
            )
          )}
        </Box>
      </Box>
    );
  }
};

export default FlightMode;
