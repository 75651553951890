/**
 * Copyright 2023 Google LLC
 */

import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Divider } from '@mui/material';
import getCollectionOrderByTimeLimitOne from '../../firebase/getCollectionOrderByTimeLimitOne';

function DCUCoralDiagnostics() {
  const [OSTelemetry, setOSTelemetry] = React.useState<any>([]);
  const [appTelemetry, setAppTelemetry] = React.useState<any>([]);

  useEffect(() => {
    getCollectionOrderByTimeLimitOne(setOSTelemetry, 'OS-TELEM-firestore');
    getCollectionOrderByTimeLimitOne(setAppTelemetry, 'APP-TELEM-firestore');
  }, []);

  return (
    <>
      <Card>
        <CardContent>
          <Box>
            <Typography variant="h6" display="inline-block" gutterBottom>
              Tello Drone Status
            </Typography>
          </Box>
          <Box className="flex flex-col gap-2">
            <Box>
              <Typography className="text-m mt-2 font-medium" gutterBottom color="text.primary">
                Hardware
              </Typography>
              <Box className="col flex flex gap-4">
                <Box>
                  <Typography className="text-base" color="text.primary">
                    Tello Battery
                  </Typography>
                  <Typography className="mb-2 text-sm" color="text.secondary">
                    {appTelemetry?.payload && appTelemetry?.payload?.tello_state?.bat
                      ? `${String(appTelemetry.payload.tello_state.bat)}%`
                      : 'No Telemetry'}
                  </Typography>
                  <Typography className="text-base" color="text.primary">
                    Tello NIC Attached
                  </Typography>
                  <Typography className="mb-2 text-sm" color="text.secondary">
                    {OSTelemetry?.payload
                      ? String(OSTelemetry.payload.secondaryNICStatus.attached)
                          .charAt(0)
                          .toUpperCase() +
                        String(OSTelemetry.payload.secondaryNICStatus.attached).slice(1)
                      : 'No Telemetry'}
                  </Typography>
                </Box>
                <Box>
                  <Typography className="text-base" color="text.primary">
                    Connected Tello SSID
                  </Typography>
                  <Typography className="mb-2  text-sm" color="text.secondary">
                    {OSTelemetry?.payload
                      ? OSTelemetry.payload.secondaryNICStatus.SSID === null ||
                        OSTelemetry.payload.secondaryNICStatus.SSID === ''
                        ? 'None'
                        : OSTelemetry.payload.secondaryNICStatus.SSID
                      : 'No Telemetry'}
                  </Typography>
                  <Typography className="text-base" color="text.primary">
                    Connection Status
                  </Typography>
                  <Typography className="mb-2  text-sm" color="text.secondary">
                    {OSTelemetry?.payload
                      ? OSTelemetry.payload.secondaryNICStatus.connectionStatus === null ||
                        OSTelemetry.payload.secondaryNICStatus.connectionStatus === ''
                        ? 'N/A'
                        : OSTelemetry.payload.secondaryNICStatus.connectionStatus
                      : 'No Telemetry'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box>
              <Typography className="text-m mt-2 font-medium" gutterBottom color="text.primary">
                Tello Config
              </Typography>
              <Typography className="text-base" color="text.primary">
                Mapped Tello
              </Typography>
              <Typography className="mb-2  text-sm" color="text.secondary">
                {OSTelemetry?.payload ? OSTelemetry.payload.mappedTelloSSID : 'No Telemetry'}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default DCUCoralDiagnostics;
