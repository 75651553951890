/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import Button from '@mui/material/Button';
import postCommand from '../../requests/postCommand';
import Box from '@mui/system/Box';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import StartIcon from '@mui/icons-material/Start';
import { UserAuth } from '../../contexts/AuthContext';
import getFlightPlan from '../../firebase/getFlightPlan';
import { AppCommandTopic, NoAttributes } from '../../requests/constants';

const Launch = () => {
  const { token } = UserAuth();

  const pushFlightPlan = async () => {
    const flightPlanData: string = await getFlightPlan('SignMappings');
    const telloCommand = {
      start_flight_plan: JSON.parse(flightPlanData),
    };
    await postCommand(telloCommand, AppCommandTopic, NoAttributes, token);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>, action: Array<any>) => {
    event.preventDefault();
    const telloCommand = { tello_command: action };
    postCommand(telloCommand, AppCommandTopic, NoAttributes, token);
  };

  return (
    <Box className="flex flex-col gap-2">
      <Button
        className="h-12 text-center"
        variant="contained"
        color="info"
        onClick={(e) => handleButtonClick(e, ['takeoff', {}, 345345])}
      >
        <FlightTakeoffIcon className="mr-2" /> Takeoff
      </Button>
      <Button
        className="h-12 text-center"
        variant="contained"
        color="success"
        onClick={pushFlightPlan}
      >
        <StartIcon className="mr-2" /> Go!
      </Button>
    </Box>
  );
};

export default Launch;
