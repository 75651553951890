/**
 * Copyright 2023 Google LLC
 */

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// Config just for Shared Resources
const firebaseConfig = {
  apiKey: 'AIzaSyDVT3-lBE87fzhKc1tRHI7kpcFezEPQZ4k',
  authDomain: 'dd-shared-team-resources.firebaseapp.com',
  projectId: 'dd-shared-team-resources',
  storageBucket: 'dd-shared-team-resources.appspot.com',
  messagingSenderId: '762024189307',
  appId: '1:762024189307:web:17858d629730e6a5b671de',
};

export const storageApp = initializeApp(firebaseConfig, 'storageApp');
export const storage = getStorage(storageApp, 'drone-derby-shared-models');
