/**
 * Copyright 2023 Google LLC
 */

import axios from 'axios';

type CreateAction = {
  action: string;
};

const commandAPI: string = process.env.REACT_APP_COMMAND_API!;

const postCommand = async (
  command: object,
  topic: string,
  attributes: Map<string, string>,
  userIdToken: string
) => {
  // Handle no COMMAND API being set
  if (!commandAPI) {
    throw Error('No Command API set in environment!');
  }
  const sendPayloadAPI = commandAPI + '/sendPayload';

  // create a JSON object
  const payloadSchema: object = {
    topicID: topic,
    attributes: Object.fromEntries(attributes),
    payload: command,
  };

  // const payload: string = JSON.stringify(payloadSchema);
  try {
    await axios.post<CreateAction>(sendPayloadAPI, payloadSchema, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userIdToken}`,
      },
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return error.message;
    } else {
      console.error('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
};

export default postCommand;
