/**
 * Copyright 2023 Google LLC
 */

import { Box, CircularProgress, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import getCollectionOrderByTimeLimitOne from '../../firebase/getCollectionOrderByTimeLimitOne';

const DCUState = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [DCUState, setDCUState] = useState<any>('');

  useEffect(() => {
    getCollectionOrderByTimeLimitOne(setDCUState, 'OS-TELEM-firestore');

    const intervalId = setInterval(() => {
      setRefreshKey((prevKey) => prevKey + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (!moment(DCUState?.publishTime?.toDate()).fromNow().includes('a few seconds ago')) {
    return (
      <Box
        className="flex flex-col items-center justify-center justify-center"
        sx={{ width: 96, height: 48 }}
        key={refreshKey}
      >
        <Box>
          <Typography variant="body2" sx={{ height: 20 }}>
            DCU State
          </Typography>
        </Box>
        <Box className="flex items-center justify-center" sx={{ width: 20, height: 20 }}>
          <CircleIcon color="error" fontSize="small" sx={{ width: 20, height: 20 }} />
        </Box>
      </Box>
    );
  } else if (DCUState?.payload?.dcuState == 'READY') {
    return (
      <Box
        className="flex flex-col items-center justify-center justify-center"
        sx={{ width: 96, height: 48 }}
      >
        <Box>
          <Typography variant="body2" sx={{ height: 20 }}>
            DCU State
          </Typography>
        </Box>
        <Box className="flex items-center justify-center" sx={{ width: 20, height: 20 }}>
          <CloudQueueIcon color="success" fontSize="small" sx={{ width: 20, height: 20 }} />
        </Box>
      </Box>
    );
  } else if (
    DCUState?.payload?.dcuState == 'INITIALISING' ||
    DCUState?.payload?.dcuState == 'BUSY_WITH_OPERATION'
  ) {
    return (
      <Box
        className="flex flex-col items-center justify-center justify-center"
        sx={{ width: 96, height: 48 }}
      >
        <Box>
          <Typography variant="body2" sx={{ height: 20 }}>
            DCU State
          </Typography>
        </Box>
        <Box className="flex items-center justify-center" sx={{ width: 20, height: 20 }}>
          <CircularProgress size="1rem" sx={{ width: 20, height: 20 }} />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        className="flex flex-col items-center justify-center justify-center"
        sx={{ width: 96, height: 48 }}
      >
        <Box>
          <Typography variant="body2" sx={{ height: 20 }}>
            DCU State
          </Typography>
        </Box>
        <Box className="flex items-center justify-center" sx={{ width: 20, height: 20 }}>
          <CircularProgress size="1rem" sx={{ width: 20, height: 20 }} />
        </Box>
      </Box>
    );
  }
};

export default DCUState;
