/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import { Fab } from '@mui/material';
import Box from '@mui/system/Box';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import postCommand from '../../requests/postCommand';
import { UserAuth } from '../../contexts/AuthContext';
import { AppCommandTopic, NoAttributes } from '../../requests/constants';

const Height = () => {
  const { token } = UserAuth();
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>, action: Array<any>) => {
    event.preventDefault();
    const telloCommand = { tello_command: action };
    postCommand(telloCommand, AppCommandTopic, NoAttributes, token);
  };

  return (
    <Box>
      <Box>
        <Box className="flex justify-center">
          <Fab
            color="primary"
            size="medium"
            onClick={(e) => handleButtonClick(e, ['up', { x: 50 }, 0])}
          >
            <KeyboardDoubleArrowUpIcon />
          </Fab>
        </Box>
        <Box className="flex gap-1">
          <Box>
            <Fab
              color="primary"
              size="medium"
              onClick={(e) => handleButtonClick(e, ['ccw', { x: 90 }, 0])}
            >
              <RotateLeftIcon />
            </Fab>
          </Box>
          <Box className="flex items-center">
            <Fab size="small" disabled></Fab>
          </Box>
          <Box>
            <Fab
              color="primary"
              size="medium"
              onClick={(e) => handleButtonClick(e, ['cw', { x: 90 }, 0])}
            >
              <RotateRightIcon />
            </Fab>
          </Box>
        </Box>
        <Box className="flex justify-center">
          <Fab
            color="primary"
            size="medium"
            onClick={(e) => handleButtonClick(e, ['down', { x: 50 }, 0])}
          >
            <KeyboardDoubleArrowDownIcon />
          </Fab>
        </Box>
      </Box>
    </Box>
  );
};

export default Height;
