/**
 * Copyright 2023 Google LLC
 */

import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import getCollectionOrderByTimeLimitOne from '../../firebase/getCollectionOrderByTimeLimitOne';

function DCUContainerDiagnostics() {
  const [containerTelemetry, setContainerTelemetry] = React.useState<any>([]);

  useEffect(() => {
    getCollectionOrderByTimeLimitOne(setContainerTelemetry, 'CONTAINER-TELEM-firestore');
  }, []);

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Drone Control Unit Container
          </Typography>
          <Typography className="text-base" color="text.primary">
            Name
          </Typography>
          <Typography className="mb-2 text-sm" color="text.secondary">
            {containerTelemetry && containerTelemetry?.payload?.containerSnapshots?.length > 0
              ? containerTelemetry?.payload?.containerSnapshots[0]?.name
              : `No container running`}
          </Typography>
          <Typography className="text-base" color="text.primary">
            Image
          </Typography>
          <Typography className="mb-2 text-sm" color="text.secondary">
            {containerTelemetry && containerTelemetry?.payload?.containerSnapshots?.length > 0
              ? containerTelemetry?.payload?.containerSnapshots[0]?.image
              : `No container running`}
          </Typography>
          <Typography className="text-base" color="text.primary">
            State
          </Typography>
          <Typography className="mb-2 text-sm" color="text.secondary">
            {containerTelemetry && containerTelemetry?.payload?.containerSnapshots?.length > 0
              ? containerTelemetry?.payload?.containerSnapshots[0]?.state
              : `No container running`}
          </Typography>
          <Typography className="text-base" color="text.primary">
            Status
          </Typography>
          <Typography className="text-sm" color="text.secondary">
            {containerTelemetry && containerTelemetry?.payload?.containerSnapshots?.length > 0
              ? containerTelemetry?.payload?.containerSnapshots[0]?.status
              : `No container running`}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default DCUContainerDiagnostics;
