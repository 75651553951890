/**
 * Copyright 2023 Google LLC
 */

import axios from 'axios';

const commandAPI: string = process.env.REACT_APP_COMMAND_API!;

const postCaptureMode = async (mode: string, model: string, userIdToken: string) => {
  // Handle no COMMAND API being set
  if (!commandAPI) {
    throw Error('No Command API set in environment!');
  }
  const sendCaptureAPI = commandAPI + '/switchDCUContainerMode';
  const payload = {
    mode: mode,
    modelPath: model,
  };

  try {
    await axios.post(sendCaptureAPI, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userIdToken}`,
      },
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return error.message;
    } else {
      console.error('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
};

export default postCaptureMode;
