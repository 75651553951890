/**
 * Copyright 2023 Google LLC
 */

import React, { createContext, useContext, useEffect, useState } from 'react';

import getCollectionOrderByTimeLimitOne from '../firebase/getCollectionOrderByTimeLimitOne';

type FlightControlProviderProps = {
  children: React.ReactNode;
};

const FlightControlContext = createContext<any>({});

export const FlightControlContextProvider: React.FC<FlightControlProviderProps> = ({
  children,
}) => {
  const [droneFlightData, setdroneFlightData] = useState<any>('');
  const [droneFlightState, setdroneFlightState] = useState<any>('');

  useEffect(() => {
    getCollectionOrderByTimeLimitOne(setdroneFlightData, 'APP-TELEM-firestore');
  }, []);

  useEffect(() => {
    if (droneFlightData?.payload?.fcs_state) {
      setdroneFlightState(droneFlightData?.payload?.fcs_state);
    }
  }, [droneFlightData]);

  return (
    <FlightControlContext.Provider value={{ droneFlightState }}>
      {children}
    </FlightControlContext.Provider>
  );
};

export const FlightControlState = () => {
  return useContext(FlightControlContext);
};
