/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import Divider from '@mui/material/Divider';
import { Box, Typography } from '@mui/material';
import DCUOSDiagnostics from './DCUOSDiagnostics';
import DCUContainerDiagnostics from './DCUContainerDiagnostics';
import DCUCoralDiagnostics from './DCUDroneDiagnostics';
import DCUOSOperations from './DCUOSOperations';
import DCUHostCommands from './DCUHostCommands';

const Diagnostics = () => {
  return (
    <Box>
      <Typography color="text.secondary" variant="h6" className="mb-1 ml-6 mt-4">
        Diagnostics & Operations
      </Typography>
      <Divider variant="middle" />
      <Box className="mb-12 mt-4 flex flex-wrap justify-center gap-4">
        <Box className="flex flex-col flex-wrap gap-4" sx={{ width: 350 }}>
          <Box>
            <DCUOSOperations />
          </Box>
          <Box>
            <DCUOSDiagnostics />
          </Box>
        </Box>
        <Box className="mb-4 flex flex-col gap-4" sx={{ width: 350 }}>
          <Box>
            <DCUContainerDiagnostics />
          </Box>
          <Box>
            <DCUCoralDiagnostics />
          </Box>
          <Box>
            <DCUHostCommands />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Diagnostics;
