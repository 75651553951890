/**
 * Copyright 2023 Google LLC
 */

import { Box, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import WifiIcon from '@mui/icons-material/Wifi';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import getCollectionOrderByTimeLimitOne from '../../firebase/getCollectionOrderByTimeLimitOne';

const DroneState = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [droneState, setDroneState] = useState<any>('');

  useEffect(() => {
    getCollectionOrderByTimeLimitOne(setDroneState, 'OS-TELEM-firestore');

    const intervalId = setInterval(() => {
      setRefreshKey((prevKey) => prevKey + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (!moment(droneState?.publishTime?.toDate()).fromNow().includes('a few seconds ago')) {
    return (
      <Box
        className="flex flex-col items-center justify-center"
        sx={{ width: 96, height: 48 }}
        key={refreshKey}
      >
        <Box>
          <Typography variant="body2" sx={{ height: 20 }}>
            Drone State
          </Typography>
        </Box>
        <Box className="flex items-center justify-center" sx={{ width: 20, height: 20 }}>
          <CircleIcon color="error" fontSize="small" sx={{ width: 20, height: 20 }} />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box className="flex flex-col items-center justify-center" sx={{ width: 96, height: 48 }}>
        <Box>
          <Typography variant="body2" sx={{ height: 20 }}>
            Drone State
          </Typography>
        </Box>
        <Box className="flex items-center justify-center" sx={{ width: 20, height: 20 }}>
          {droneState?.payload?.secondaryNICStatus?.connectionStatus == '100 (connected)' ? (
            <WifiIcon color="success" fontSize="small" sx={{ width: 20, height: 20 }} />
          ) : (
            <CircleIcon color="error" fontSize="small" sx={{ width: 20, height: 20 }} />
          )}
        </Box>
      </Box>
    );
  }
};

export default DroneState;
