/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Routes, Route } from 'react-router-dom';
import NavBar from './navigation/NavBar';
import Mappings from './mappings/Mappings';
import Diagnostics from './diagnostics/Diagnostics';
import Operations from './operations/Operations';
import ImageCapture from './imageCapture/ImageCapture';
import Control from './control/Control';
import { UserAuth } from '../contexts/AuthContext';
import SignIn from './signIn/SignIn';
import Spinner from './spinner/Spinner';
import Footer from './footer/Footer';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const { user, loggedIn } = UserAuth();

  if (loggedIn == false) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        {user ? (
          <>
            <NavBar />
            <Routes>
              <Route path="/" element={<Diagnostics />} />
              <Route path="mappings" element={<Mappings />} />
              <Route path="controls" element={<Control />} />
              <Route path="image-capture" element={<ImageCapture />} />
              <Route path="operations" element={<Operations />} />
            </Routes>
            <Footer />
          </>
        ) : (
          <SignIn />
        )}
      </ThemeProvider>
    </>
  );
}

export default App;
