/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { AuthContextProvider } from './contexts/AuthContext';
import { StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import { FlightControlContextProvider } from './contexts/FlightControlStateContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <FlightControlContextProvider>
        <StyledEngineProvider injectFirst>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StyledEngineProvider>
      </FlightControlContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
