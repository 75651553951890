/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import Divider from '@mui/material/Divider';
import { Box, Typography } from '@mui/material';
import DCUOperations from './DCUOperations';

const Operations = () => {
  return (
    <Box>
      <Typography color="text.secondary" variant="h6" className="mb-1 ml-6 mt-4">
        DCU Operations
      </Typography>
      <Divider variant="middle" />
      <Box className="mb-12 mt-4 flex flex-wrap justify-center gap-4">
        <Box className="flex flex-col flex-wrap gap-4">
          <DCUOperations />
        </Box>
      </Box>
    </Box>
  );
};

export default Operations;
