/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import { MoonLoader } from 'react-spinners';

const Spinner = () => {
  return (
    <div>
      <MoonLoader color="#4285F4" size={100} speedMultiplier={0.5} />
    </div>
  );
};

export default Spinner;
