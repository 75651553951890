/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import { Box, Paper } from '@mui/material';
import DroneMode from './DroneMode';
import DCUState from './DCUState';
import DroneState from './DroneState';

const Footer = () => {
  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <Box className="flex flex-row justify-center">
        <Box>
          <DCUState />
        </Box>
        <Box>
          <DroneState />
        </Box>
        <Box>
          <DroneMode />
        </Box>
      </Box>
    </Paper>
  );
};

export default Footer;
