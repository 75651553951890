/**
 * Copyright 2023 Google LLC
 */

import React, { useEffect } from 'react';
import Box from '@mui/system/Box';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import { Typography } from '@mui/material';
import getCollectionOrderByTimeLimitOne from '../../firebase/getCollectionOrderByTimeLimitOne';

const DroneBatteryLevel = () => {
  const [droneBatteryLevel, setDroneBatteryLevel] = React.useState<any>();

  useEffect(() => {
    getCollectionOrderByTimeLimitOne(setDroneBatteryLevel, 'APP-TELEM-firestore');
  }, []);

  return (
    <Box className="mt-2 flex flex-col items-center justify-start gap-2">
      <Box className="flex flex-row items-center justify-center gap-1">
        <Typography variant="h5">Drone Battery</Typography>
        <BatteryFullIcon fontSize="medium" />
      </Box>
      <Box>
        <Typography variant="h6" className="mb-2">
          {droneBatteryLevel?.payload && droneBatteryLevel?.payload?.tello_state?.bat
            ? `${String(droneBatteryLevel.payload.tello_state.bat)}%`
            : 'No Telemetry'}
        </Typography>
      </Box>
    </Box>
  );
};

export default DroneBatteryLevel;
