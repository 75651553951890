/**
 * Copyright 2023 Google LLC
 */

import { Box, Divider, Typography } from '@mui/material';
import ActionsDataGrid from './ActionsDataGrid';
import React from 'react';

const Mappings = () => {
  return (
    <Box>
      <Typography color="text.secondary" variant="h6" className="mb-1 ml-6 mt-4">
        Flight Plan
      </Typography>
      <Divider variant="middle" />
      <Box className="mb-16 ml-5 mr-5 mt-5">
        <ActionsDataGrid />
      </Box>
    </Box>
  );
};

export default Mappings;
