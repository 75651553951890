/**
 * Copyright 2023 Google LLC
 */

import { DocumentData, collection, getDocs } from 'firebase/firestore';
import { db } from './config';

type FlightPlan = {
  [key: string]: any[][];
};

const getFlightPlan = async (collectionName: string) => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  let flightPlan: FlightPlan = {};
  querySnapshot.forEach((doc: DocumentData) => {
    const formattedSteps = doc
      .data()
      .steps?.filter((step: any) => {
        return step !== undefined;
      })
      .map((step: any) => {
        return [step.option, { x: step.value }, 0];
      });
    flightPlan[doc.data().name] = [['fly_to', doc.data().approach]].concat(formattedSteps);
  });

  return JSON.stringify(flightPlan);
};

export default getFlightPlan;
