/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import Button from '@mui/material/Button';
import postCommand from '../../requests/postCommand';
import Box from '@mui/system/Box';
import PanToolIcon from '@mui/icons-material/PanTool';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { UserAuth } from '../../contexts/AuthContext';
import { AppCommandTopic, NoAttributes } from '../../requests/constants';

const Descent = () => {
  const { token } = UserAuth();

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>, action: Array<any>) => {
    event.preventDefault();
    const telloCommand = { tello_command: action };
    postCommand(telloCommand, AppCommandTopic, NoAttributes, token);
  };

  return (
    <Box className="flex flex-col gap-2">
      <Button
        className="h-12 text-center"
        variant="contained"
        color="info"
        onClick={(e) => handleButtonClick(e, ['land', {}, 345345])}
      >
        <FlightLandIcon className="mr-2" /> Land
      </Button>
      <Button
        className="h-12 text-center"
        variant="contained"
        color="error"
        onClick={(e) => handleButtonClick(e, ['emergency', {}, 345345])}
      >
        <PanToolIcon className="mr-2" /> EMERGENCY STOP
      </Button>
    </Box>
  );
};

export default Descent;
