/**
 * Copyright 2023 Google LLC
 */

import React, { useEffect } from 'react';
import getCollectionOrderByTime from '../../firebase/getCollectionOrderByTime';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import { DocumentData } from 'firebase/firestore';
import { useState } from 'react';

import moment from 'moment';

function DCUOperations() {
  const [operations, setOperations] = React.useState<any>([]);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    getCollectionOrderByTime(setOperations, 'dcuOperations', 'lastUpdate', 10);

    const intervalId = setInterval(() => {
      setRefreshKey((prevKey) => prevKey + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="DCU Operations">
          <TableHead>
            <TableRow>
              <TableCell>Message ID</TableCell>
              <TableCell>Send Time</TableCell>
              <TableCell>Operation Type</TableCell>
              <TableCell>Topic ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Status Description</TableCell>
              <TableCell>Last Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody key={refreshKey}>
            {operations.map((row: DocumentData) => (
              <TableRow key={row.messageID}>
                <TableCell component="th" scope="row">
                  {row.messageID}
                </TableCell>
                <TableCell component="th" scope="row">
                  {moment(row.publishTime.toDate()).fromNow()}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.eventType}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.topicID}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.status}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.updateDescription}
                </TableCell>
                <TableCell component="th" scope="row">
                  {moment(row.lastUpdate.toDate()).fromNow()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default DCUOperations;
