/**
 * Copyright 2023 Google LLC
 */

import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './config';

const addDocument = async (collection: string, documentId: string | number, data: any) => {
  const docRef = doc(db, collection, documentId as string);
  await setDoc(docRef, { ...data, publishTime: serverTimestamp() }).catch((error) => {
    console.error(error);
  });
};

export default addDocument;
