/**
 * Copyright 2023 Google LLC
 */

import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PreviewIcon from '@mui/icons-material/Preview';
import { DataGrid, GridActionsCellItem, GridColumns, GridRowId } from '@mui/x-data-grid';
import SignMappingActionForm from './SignMappingActionForm';
import { Box, Paper } from '@mui/material';
import addDocument from '../../firebase/addDocument';
import PreviewMappings from './PreviewMappings';
import { useEffect } from 'react';
import getCollection from '../../firebase/getCollection';

const ActionsDataGrid = () => {
  type Rows =
    | {
        approach: {
          max_speed?: number | null;
          distance_from_target_cm: number;
        };
        id: string;
        imgURL: string;
        label: string;
        name: string;
        steps?:
          | {
              option: string | null;
              value: number | null;
            }[]
          | null
          | undefined;
      }
    | undefined;

  const [rows, setRows] = React.useState<Rows[]>([]);
  const [preview, setPreview] = React.useState<Rows>(undefined);
  const [isOpen, setIsOpen] = React.useState(false);
  const [id, setId] = React.useState<GridRowId>('');

  useEffect(() => {
    getCollection(setRows, 'SignMappings');
  }, []);

  useEffect(() => {
    const previewRowIndex: number = rows.findIndex((row) => row?.id === id);
    setPreview(rows[previewRowIndex]);
  }, [rows]);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleEditClick = (id: GridRowId) => () => {
    setId(id);
    handleClickOpen();
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const clearRowStepsIndex: number = rows.findIndex((row) => row?.id === id);
    const updatedRows: Rows[] | null = [...rows];
    delete updatedRows[clearRowStepsIndex]!.steps;
    delete updatedRows[clearRowStepsIndex]!.approach.max_speed;
    addDocument('SignMappings', id, updatedRows[clearRowStepsIndex]);
  };

  const handlePreviewClick = (id: GridRowId) => () => {
    const previewRowIndex: number = rows.findIndex((row) => row?.id === id);
    setPreview(rows[previewRowIndex]);
  };

  const columns: GridColumns = [
    {
      field: 'order',
      headerName: 'Order',
      width: 80,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: 'imgURL',
      headerName: 'Sign',
      width: 80,
      editable: false,
      sortable: false,
      renderCell: (params) => <img src={params.value} alt="sign mapping" width="50px" />,
    },
    {
      field: 'steps',
      headerName: 'Actions',
      type: 'string',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (params) => {
        if (params.value != undefined || params.value != null) return params.value.length;
        else return `Not Configured`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Options',
      width: 150,
      cellClassName: 'actions',
      sortable: false,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<PreviewIcon />}
            label="Preview"
            className="textPrimary"
            onClick={handlePreviewClick(id)}
            color="inherit"
            key={id}
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            key={id}
          />,
          <GridActionsCellItem
            icon={<DeleteForeverIcon />}
            label="Delete"
            className="textPrimary"
            onClick={handleDeleteClick(id)}
            color="inherit"
            key={id}
          />,
        ];
      },
    },
  ];

  return (
    <Box className="flex w-full flex-col gap-2 md:flex-row">
      <Box
        sx={{
          height: '80vh',
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        {isOpen && (
          <SignMappingActionForm id={id} setIsOpen={setIsOpen} isOpen={isOpen} rows={rows} />
        )}
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'order', sort: 'asc' }],
            },
            columns: {
              columnVisibilityModel: {
                order: false,
                imgURL: true,
                steps: true,
                actions: true,
              },
            },
          }}
          columns={columns}
          rows={rows}
          disableSelectionOnClick
          autoPageSize
          rowsPerPageOptions={[20]}
          rowHeight={60}
        />
      </Box>
      <Box
        sx={{
          height: '80vh',
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            height: '80vh',
            overflow: 'auto',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}
        >
          {preview ? (
            <PreviewMappings preview={preview} />
          ) : (
            <Box className="flex h-full flex-col items-center justify-center">
              No Preview Selected.
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default ActionsDataGrid;
