/**
 * Copyright 2023 Google LLC
 */

import { ref, listAll } from 'firebase/storage';
import { storage } from './sharedModelsConfig';

const getSharedModels = async (): Promise<string[]> => {
  const storageRef = ref(storage, '/');
  try {
    const result = await listAll(storageRef);
    return result.items.map((item) => item.fullPath).filter((path) => path.endsWith('.tflite'));
  } catch (error) {
    console.error('Error listing files:', error);
    throw error;
  }
};

export default getSharedModels;
