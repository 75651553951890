/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import { useSearchParams } from 'react-router-dom';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import googleCloudLogo from '../../assets/google_cloud_logo.svg';

import { UserAuth } from '../../contexts/AuthContext';

const SignIn = () => {
  const { signIn } = UserAuth();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    signIn(data.get('email'), data.get('password'));
  };

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const password = searchParams.get('password');

  return (
    // <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">Google Cloud Drone Derby</Typography>
        {/* <Avatar sx={{ m: 1 }}> */}
        {/* <LockOutlinedIcon /> */}
        <img className="relative mb-2 mt-6 h-16" alt="Google Cloud Logo" src={googleCloudLogo} />
        {/* </Avatar> */}
        <Typography variant="subtitle1">Sign in</Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            autoComplete="current-password"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SignIn;
