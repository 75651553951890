/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import Divider from '@mui/material/Divider';
import { Box, Typography } from '@mui/material';
import DisplayImages from './DisplayImages';
import DroneMode from './DroneMode';

const Diagnostics = () => {
  return (
    <Box>
      <Typography color="text.secondary" variant="h6" className="mb-1 ml-6 mt-4">
        Image Capture
      </Typography>
      <Divider variant="middle" />
      <Box className="mb-12">
        <Box className="m-0">
          <DroneMode />
        </Box>
        <Box className="mt-4 flex flex-row justify-center gap-6">
          <DisplayImages />
        </Box>
      </Box>
    </Box>
  );
};

export default Diagnostics;
