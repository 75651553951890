/**
 * Copyright 2023 Google LLC
 */

import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import moment from 'moment';
import getCollectionOrderByTimeLimitOne from '../../firebase/getCollectionOrderByTimeLimitOne';

function DCUOSOperations() {
  const [refreshKey, setRefreshKey] = useState(0);
  const [OSTelemetry, setOSTelemetry] = React.useState<any>([]);

  useEffect(() => {
    getCollectionOrderByTimeLimitOne(setOSTelemetry, 'OS-TELEM-firestore');

    const intervalId = setInterval(() => {
      setRefreshKey((prevKey) => prevKey + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Card>
        <CardContent>
          <Box>
            <Typography variant="h6" display="inline-block" gutterBottom>
              Drone Control Unit Operations
            </Typography>
          </Box>
          <Typography className="text-base" color="text.primary">
            Cloud Connection Status:
          </Typography>
          <Box className="flex flex-row gap-8" key={refreshKey}>
            {OSTelemetry?.payload ? (
              <Typography
                className="mb-2 text-sm"
                color={
                  moment(OSTelemetry?.publishTime?.toDate()).fromNow().includes('a few seconds ago')
                    ? 'green'
                    : 'red'
                }
              >
                Last seen: {moment(OSTelemetry?.publishTime?.toDate()).fromNow()}
              </Typography>
            ) : (
              <Typography className="mb-2 text-sm" color="text.secondary">
                No Telemetry
              </Typography>
            )}
          </Box>
          <Box className="flex flex-row gap-8">
            <Box>
              <Typography className="text-base" color="text.primary">
                Operation Status
              </Typography>
              <Typography className="mb-2 text-sm" color="text.secondary">
                {OSTelemetry?.payload
                  ? OSTelemetry.payload.dcuState
                    ? OSTelemetry.payload.dcuState
                    : `None`
                  : 'No Telemetry'}
              </Typography>
              <Typography className="text-base" color="text.primary">
                Executing Operation ID
              </Typography>
              <Typography className="mb-2 text-sm" color="text.secondary">
                {OSTelemetry?.payload
                  ? OSTelemetry.payload.inProgressOperationID
                    ? OSTelemetry.payload.inProgressOperationID
                    : `None`
                  : 'No Telemetry'}
              </Typography>
              <Typography className="text-base" color="text.primary">
                Executing Operation Type
              </Typography>
              <Typography className="text-sm" color="text.secondary">
                {OSTelemetry?.payload
                  ? OSTelemetry.payload.inProgressOperationType
                    ? OSTelemetry.payload.inProgressOperationType
                    : `None`
                  : 'No Telemetry'}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default DCUOSOperations;
