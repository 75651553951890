/**
 * Copyright 2023 Google LLC
 */

/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
} from 'firebase/auth';

import { auth } from '../firebase/config';

interface AppContextInterface {
  user: User | null;
  signIn: (email: any, password: any) => Promise<UserCredential>;
  loggedIn: boolean;
  logout: () => Promise<void>;
  token: string;
}

type AuthProviderProps = {
  children: React.ReactNode;
};

const UserContext = createContext<AppContextInterface>({} as AppContextInterface);

export const AuthContextProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState('');

  const signIn = (email: string, password: string): Promise<UserCredential> => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        currentUser.getIdToken(true).then((token) => {
          setToken(token);
        });
      }
      setIsLoggedIn(true);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, signIn, logout, loggedIn, token }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
