/**
 * Copyright 2023 Google LLC
 */

import { collection, DocumentData, limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from './config';

const getCollectionOrderByTimeLimitOne = (setState: any, collectionName: string) => {
  const q = query(collection(db, collectionName), orderBy('publishTime', 'desc'), limit(1));
  const unsubscribe = onSnapshot(
    q,
    (snapshot) => {
      let docs: any = {};
      snapshot.forEach((doc: DocumentData) => {
        docs = {
          id: doc.id,
          ...doc.data(),
        };
      });
      setState(docs);
    },
    (error) => {
      console.error(error);
    }
  );
  return () => unsubscribe();
};

export default getCollectionOrderByTimeLimitOne;
