/**
 * Copyright 2023 Google LLC
 */

import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import AccountCircle from '@mui/icons-material/AccountCircle';
import ControlCameraTwoToneIcon from '@mui/icons-material/ControlCameraTwoTone';
import ForkRightTwoToneIcon from '@mui/icons-material/ForkRightTwoTone';
import PendingIcon from '@mui/icons-material/Pending';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { UserAuth } from '../../contexts/AuthContext';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { logout, user } = UserAuth();

  const toggleDrawer = () => () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSignOut = () => {
    logout();
  };

  const list = () => (
    <Box sx={{ width: 300, mt: 8 }} role="presentation" onClick={toggleDrawer()}>
      <List>
        <Link to="/">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <MonitorHeartOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Diagnostics & Operations'} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/image-capture">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <PhotoCameraIcon />
              </ListItemIcon>
              <ListItemText primary={'Image Capture'} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/mappings">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ForkRightTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary={'Flight Plan'} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/controls">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ControlCameraTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary={'Flight Controls'} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/operations">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <PendingIcon />
              </ListItemIcon>
              <ListItemText primary={'Operations'} />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="relative" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton
              onClick={toggleDrawer()}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              data-testid="menu-button"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" className="text-center" sx={{ flexGrow: 1 }}>
              Google Cloud Drone Derby
            </Typography>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {user && <MenuItem disabled>User: {user.email}</MenuItem>}
                <MenuItem onClick={handleSignOut}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer anchor={'left'} open={isOpen} onClose={toggleDrawer()}>
        {list()}
      </Drawer>
    </>
  );
};

export default NavBar;
