/**
 * Copyright 2023 Google LLC
 */

import { collection, DocumentData, onSnapshot, orderBy, query, limit } from 'firebase/firestore';
import { db } from './config';

const getCollectionOrderByTime = (
  setState: any,
  collectionName: string,
  timestampField: string = 'timestamp',
  limitValue: number = -1
) => {
  const q =
    limitValue > 0
      ? query(collection(db, collectionName), orderBy(timestampField, 'desc'), limit(limitValue))
      : query(collection(db, collectionName), orderBy(timestampField, 'desc'));

  const unsubscribe = onSnapshot(
    q,
    (snapshot) => {
      const docs: any = [];
      snapshot.forEach((doc: DocumentData) => {
        docs.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setState(docs);
    },
    (error) => {
      console.error(error);
    }
  );
  return () => unsubscribe();
};

export default getCollectionOrderByTime;
