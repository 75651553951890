/**
 * Copyright 2023 Google LLC
 */

// AppCommandTopic is the name of the PubSub Topic that sends commands to the application container
export const AppCommandTopic = 'APP-CMD';

// HostCommandTopic is the name of the PubSub topic that sends commands to the underlying host OS
export const HostCommandTopic = 'HOST-CMD';
// EventTypeName is the PubSub attribute key that identifies the event type for the DCU host commands
export const EventTypeName = 'type';

// NoAttributes is an empty set of attrivutes
export const NoAttributes = new Map();
// NoPayload is an empty payload
export const NoPayload = {};

// DCUEventShutdownHost shuts down the DCU
export const DCUEventShutdownHost = 'dcu.shutdown_host';
// DCUEventRestartHost restarts the DCU
export const DCUEventRestartHost = 'dcu.reboot_host';
// DCUEventRestartHost restarts the DCU Agent
export const DCUEventRestartAgent = 'dcu.restart_agent';

export const BaseModelPath = 'gs://drone-derby-shared-models/';
