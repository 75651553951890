/**
 * Copyright 2023 Google LLC
 */

import React, { useEffect, useState } from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import getCollectionOrderByTime from '../../firebase/getCollectionOrderByTime';

export default function DisplayImages() {
  type Images = {
    id: string;
    resizedURL: string;
    srcURL: string;
    timestamp: string;
  }[];

  const [images, setImages] = useState<Images>([]);

  useEffect(() => {
    getCollectionOrderByTime(setImages, 'image-capture');
  }, []);

  return (
    <Box className="flex flex-wrap">
      {images.map((item, index) => (
        <div key={index} className="flex flex-row justify-center p-1">
          <ImageListItem
            key={index}
            sx={{
              opacity: '.9',
              transition: 'opacity .3s linear',
              '&:hover': { opacity: 1 },
              width: 200,
            }}
          >
            <img
              className="w-52 h-52"
              src={`${item.resizedURL}`}
              srcSet={`${item.resizedURL}`}
              alt={item.id}
              loading="lazy"
            />
            <Typography
              variant="body2"
              component="span"
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                color: 'white',
                background: 'rgba(0,0,0, .3)',
                p: '5px',
                borderTopRightRadius: 9,
              }}
            >
              {moment(item.timestamp).fromNow()}
            </Typography>
          </ImageListItem>
        </div>
      ))}
    </Box>
  );
}
