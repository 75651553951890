/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import { Fab } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/system/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import postCommand from '../../requests/postCommand';
import { UserAuth } from '../../contexts/AuthContext';
import { AppCommandTopic, NoAttributes } from '../../requests/constants';

const Directional = () => {
  const { token } = UserAuth();
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>, action: Array<any>) => {
    event.preventDefault();
    const telloCommand = { tello_command: action };
    postCommand(telloCommand, AppCommandTopic, NoAttributes, token);
  };

  return (
    <Box>
      <Box>
        <Box className="flex justify-center">
          <Fab
            color="primary"
            size="medium"
            onClick={(e) => handleButtonClick(e, ['forward', { x: 20 }, 0])}
          >
            <ExpandLessIcon />
          </Fab>
        </Box>
        <Box className="flex gap-1">
          <Box>
            <Fab
              color="primary"
              size="medium"
              onClick={(e) => handleButtonClick(e, ['left', { x: 40 }, 0])}
            >
              <KeyboardArrowLeftIcon />
            </Fab>
          </Box>
          <Box className="flex items-center">
            <Fab size="small" disabled></Fab>
          </Box>
          <Box>
            <Fab
              color="primary"
              size="medium"
              onClick={(e) => handleButtonClick(e, ['right', { x: 40 }, 0])}
            >
              <KeyboardArrowRightIcon />
            </Fab>
          </Box>
        </Box>
        <Box className="flex justify-center">
          <Fab
            color="primary"
            size="medium"
            onClick={(e) => handleButtonClick(e, ['back', { x: 20 }, 0])}
          >
            <ExpandMoreIcon />
          </Fab>
        </Box>
      </Box>
    </Box>
  );
};

export default Directional;
