/**
 * Copyright 2023 Google LLC
 */

import { collection, DocumentData, onSnapshot } from 'firebase/firestore';
import { db } from './config';

const getCollection = (setState: any, collectionName: string) => {
  const unsubscribe = onSnapshot(
    collection(db, collectionName),
    (snapshot) => {
      const docs: any = [];
      snapshot.forEach((doc: DocumentData) => {
        docs.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setState(docs);
    },
    (error) => {
      console.error(error);
    }
  );
  return () => unsubscribe();
};

export default getCollection;
