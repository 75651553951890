/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { inputOptionSpec } from './inputOptionSpec';

const PreviewMappings = (preview: any) => {
  const getUnit = (optionInput: string) => {
    const getInputOptionSpec = inputOptionSpec();
    const inputVariant = getInputOptionSpec.find((option) => option.value === optionInput);
    return inputVariant?.unitValue;
  };

  const getLabel = (optionInput: string) => {
    const getInputOptionSpec = inputOptionSpec();
    const inputVariant = getInputOptionSpec.find((option) => option.value === optionInput);
    return inputVariant?.label;
  };

  const getSpeed = (speed: number) => {
    if (speed == 0) {
      return 'Slow';
    } else if (speed == 50) {
      return 'Medium';
    } else if (speed == 100) {
      return 'Fast';
    } else {
      return `Not Set`;
    }
  };

  const title = preview.preview.label
    .toLowerCase()
    .replace(/\b\w/g, (s: String) => s.toUpperCase());

  return (
    <Box className="mx-3 mb-1 mt-3">
      <Box>
        <Typography variant="h6" gutterBottom>
          {title} Flight Plan
        </Typography>
        <Box>
          <img src={preview.preview.imgURL} alt="sign mapping" width="80px" />
        </Box>
      </Box>
      <Box>
        <Typography className="mt-2" variant="h6" gutterBottom>
          Approach
        </Typography>
      </Box>
      <Box className="ml-2 mr-2 mt-4">
        <Typography variant="body1" gutterBottom>
          Speed:{' '}
          {preview.preview.approach?.max_speed
            ? getSpeed(preview.preview.approach.max_speed)
            : 'Not Set'}
        </Typography>
        {/* Removed for Next */}
        {/* <Typography variant="body1" gutterBottom>
          Distance:{' '}
          {preview.preview.approach
            ? `${preview.preview.approach.distance_from_target_cm}cm`
            : `Not set`}
        </Typography> */}
        <Box></Box>
      </Box>
      <Typography className="mt-6" variant="h6" gutterBottom>
        Transit
      </Typography>
      {preview.preview.steps && preview.preview.steps.length > 0 ? (
        <Box className="ml-2 mr-2 mt-4">
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">Step</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" align="right">
                        Option
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" align="right">
                        Value
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {preview.preview.steps.map((row: any, index: number) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">{index + 1}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1">{getLabel(row.option)}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1">
                          {row.value != 0 && row.value}
                          {getUnit(row.option)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      ) : (
        <Box className="ml-2">No actions configured for this sign yet!</Box>
      )}
    </Box>
  );
};

export default PreviewMappings;
