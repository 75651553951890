/**
 * Copyright 2023 Google LLC
 */

import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Divider } from '@mui/material';
import getCollectionOrderByTimeLimitOne from '../../firebase/getCollectionOrderByTimeLimitOne';

function DCUOSDiagnostics() {
  const [OSTelemetry, setOSTelemetry] = React.useState<any>([]);

  useEffect(() => {
    getCollectionOrderByTimeLimitOne(setOSTelemetry, 'OS-TELEM-firestore');
  }, []);

  return (
    <>
      <Card>
        <CardContent>
          <Box>
            <Typography variant="h6" display="inline-block" gutterBottom>
              Drone Control Unit Hardware
            </Typography>
          </Box>
          <Box className="flex flex-col gap-2">
            <Box>
              <Typography className="text-m mt-2 font-medium" gutterBottom color="text.primary">
                Hardware
              </Typography>
              <Box className="col flex flex gap-4">
                <Box>
                  <Typography className="text-base" color="text.primary">
                    Temperature
                  </Typography>
                  <Typography className="mb-2 text-sm" color="text.secondary">
                    {OSTelemetry?.payload
                      ? `${OSTelemetry.payload.temperature} °C`
                      : 'No Telemetry'}
                  </Typography>
                  <Typography className="text-base" color="text.primary">
                    CPU Utilisation (1 min)
                  </Typography>
                  <Typography className="mb-2  text-sm" color="text.secondary">
                    {OSTelemetry?.payload
                      ? `${Math.round((OSTelemetry.payload.loadAverage['1min'] / 4) * 100)}%`
                      : 'No Telemetry'}
                  </Typography>
                </Box>
                <Box>
                  <Typography className="text-base" color="text.primary">
                    Free Memory
                  </Typography>
                  <Typography className="mb-2  text-sm" color="text.secondary">
                    {OSTelemetry?.payload
                      ? `${OSTelemetry.payload.freeMemory} MiB`
                      : 'No Telemetry'}
                  </Typography>
                  <Typography className="text-base" color="text.primary">
                    Uptime
                  </Typography>
                  <Typography className="mb-2  text-sm" color="text.secondary">
                    {OSTelemetry?.payload ? OSTelemetry.payload.uptime : 'No Telemetry'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box>
              <Typography className="text-m mt-2 font-medium" gutterBottom color="text.primary">
                WLAN Status
              </Typography>
              <Box className="col flex flex gap-4">
                <Box>
                  <Typography className="text-base" color="text.primary">
                    Connected SSID
                  </Typography>
                  <Typography className="mb-2 text-sm" color="text.secondary">
                    {OSTelemetry?.payload
                      ? OSTelemetry.payload.primaryNICStatus.SSID &&
                        OSTelemetry.payload.primaryNICStatus.SSID !== ''
                        ? OSTelemetry.payload.primaryNICStatus.SSID
                        : 'N/A'
                      : 'No Telemetry'}
                  </Typography>
                  <Typography className="text-base" color="text.primary">
                    Connection Status
                  </Typography>
                  <Typography className="text-sm" color="text.secondary">
                    {OSTelemetry?.payload
                      ? OSTelemetry.payload.primaryNICStatus.connectionStatus
                      : 'No Telemetry'}
                  </Typography>
                </Box>
                <Box>
                  <Typography className="text-base" color="text.primary">
                    Interface IP
                  </Typography>
                  <Typography className="text-sm" color="text.secondary">
                    {OSTelemetry?.payload
                      ? OSTelemetry.payload.primaryNICStatus.ipAddress &&
                        OSTelemetry.payload.primaryNICStatus.ipAddress !== ''
                        ? OSTelemetry.payload.primaryNICStatus.ipAddress
                        : 'N/A'
                      : 'No Telemetry'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box>
              <Typography className="text-m mt-2 font-medium" gutterBottom color="text.primary">
                LAN Status
              </Typography>
              <Box className="col flex flex gap-4">
                <Box>
                  <Typography className="text-base" color="text.primary">
                    Connection Status
                  </Typography>
                  <Typography className="text-sm" color="text.secondary">
                    {OSTelemetry?.payload
                      ? OSTelemetry.payload.managementNICStatus.connectionStatus
                      : 'No Telemetry'}
                  </Typography>
                </Box>
                <Box>
                  <Typography className="text-base" color="text.primary">
                    Interface IP
                  </Typography>
                  <Typography className="text-sm" color="text.secondary">
                    {OSTelemetry?.payload
                      ? OSTelemetry.payload.managementNICStatus.ipAddress
                      : 'No Telemetry'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default DCUOSDiagnostics;
