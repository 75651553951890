/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import postCommand from '../../requests/postCommand';
import {
  HostCommandTopic,
  NoPayload,
  DCUEventShutdownHost,
  DCUEventRestartAgent,
  DCUEventRestartHost,
  EventTypeName,
} from '../../requests/constants';
import { UserAuth } from '../../contexts/AuthContext';

import Box from '@mui/system/Box';

const DCUHostCommands = () => {
  const { token } = UserAuth();

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>, eventType: string) => {
    event.preventDefault();
    const attributes = new Map([[EventTypeName, eventType]]);
    postCommand(NoPayload, HostCommandTopic, attributes, token);
  };

  return (
    <Card>
      <CardContent>
        <Box>
          <Typography variant="h6" display="inline-block" gutterBottom>
            DCU Control Commands
          </Typography>
        </Box>
        <Box className="flex flex-row gap-2">
          <Button
            className="w-40 text-center"
            variant="contained"
            color="info"
            onClick={(e) => handleButtonClick(e, DCUEventRestartHost)}
          >
            Restart DCU
          </Button>
          <Button
            className="w-40 text-center"
            variant="contained"
            color="info"
            onClick={(e) => handleButtonClick(e, DCUEventRestartAgent)}
          >
            Restart Agent
          </Button>
          <Button
            className="w-40 text-center"
            variant="contained"
            color="error"
            onClick={(e) => handleButtonClick(e, DCUEventShutdownHost)}
          >
            Shutdown DCU
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DCUHostCommands;
