/**
 * Copyright 2023 Google LLC
 */

import { doc, getDoc } from 'firebase/firestore';
import { db } from './config';

const getDocument = async (setState: any, collectionName: string, documentName: string) => {
  const docRef = doc(db, collectionName, documentName);
  const docSnap = await getDoc(docRef);
  setState(docSnap.data());
};

export default getDocument;
