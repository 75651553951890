/**
 * Copyright 2023 Google LLC
 */

import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  FormControl,
  FormGroup,
  InputLabel,
  List,
  ListItem,
  MenuItem,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import React, { useEffect, useState } from 'react';
import postCaptureMode from '../../requests/postCaptureMode';
import { UserAuth } from '../../contexts/AuthContext';
import postCommand from '../../requests/postCommand';
import { AppCommandTopic, NoAttributes } from '../../requests/constants';
import addDocument from '../../firebase/addDocument';
import Spinner from '../spinner/Spinner';
import getCollection from '../../firebase/getCollection';
// import getDocument from '../../firebase/getDocument';
// import getImageCapture from '../../firebase/getImageCapture';
import getDocument from '../../firebase/getDocument';

const DroneMode = () => {
  type Rows =
    | {
        approach: {
          max_speed: number;
          distance_from_target_cm: number;
        };
        id: string;
        imgURL: string;
        label: string;
        name: string;
        steps:
          | {
              option: string | null;
              value: number | null;
            }[]
          | null;
      }
    | undefined;

  type CaptureState = {
    enabled: boolean;
    gate: {
      id: string;
      imgURL: string;
      label: string;
      name: string;
    };
    model: string;
    publishTime: string;
    state: string;
  };

  const { token } = UserAuth();
  const [gate, setGate] = useState('');
  const [model, setModel] = useState('');
  const [rows, setRows] = useState<Rows[]>([]);
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imageCaptureState, setImageCaptureState] = useState<CaptureState>({
    enabled: false,
    gate: {
      id: '',
      imgURL: '',
      label: '',
      name: '',
    },
    model: '',
    publishTime: '',
    state: '',
  });
  // Remove comment when integrated with IMG-TELEM data
  // const [isRunning, setIsRunning] = useState(false);
  // const [isRunning] = useState(false);

  useEffect(() => {
    getCollection(setRows, 'SignMappings');
    getDocument(setImageCaptureState, 'ImageCapture', 'captureState');
  }, []);

  useEffect(() => {
    if (imageCaptureState) {
      setChecked(imageCaptureState.enabled);
      imageCaptureState?.gate?.id ? setGate(imageCaptureState.gate?.id) : setGate('');
      imageCaptureState?.model ? setModel(imageCaptureState.model) : setModel('');
    }
    // Remove comment when integrated with IMG-TELEM data
    // if (imageCaptureState.state == 'running') {
    //   setIsRunning(true);
    // }
    // }
    setIsLoading(false);
  }, [imageCaptureState]);

  const handleSelectedGateChange = (event: SelectChangeEvent) => {
    setGate(event.target.value as string);
  };

  // Commenting out the below until we need users to be able to specify the model path
  // const handleModelChange = (
  //   element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  // ) => {
  //   setModel(element.target.value as string);
  // };

  const handleCaptureClick = () => {
    setChecked(true);
    addDocument('ImageCapture', 'captureState', {
      enabled: true,
    });
    postCaptureMode('image_capture', model, token);
  };

  const handlePlayClick = () => {
    // Remove comment when integrated with IMG-TELEM data
    // setIsRunning(true);
    const payload = {
      imagecapture: {
        cmd: 'run',
        gate_label: gate,
      },
    };
    const getCaptureGate = rows.find((captureGate) => captureGate?.id === gate);
    addDocument('ImageCapture', 'captureState', {
      enabled: true,
      state: 'running',
      gate: getCaptureGate,
      model: model,
    });
    postCommand(payload, AppCommandTopic, NoAttributes, token);
  };

  return (
    <Card className="m-4">
      {checked != undefined && isLoading == false ? (
        <CardContent className="p-0">
          <List>
            <ListItem className="flex flex-row flex-wrap">
              <FormGroup className="flex flex-row flex-wrap gap-4">
                {/* Commenting out the below until we need users to be able to specify the model path */}
                {/* <TextField
                  id="model"
                  name="model"
                  label="Model"
                  type="string"
                  value={model}
                  className="w-48 mr-2 mt-0 mb-0"
                  onChange={(element) => handleModelChange(element)}
                /> */}
                <Box className="flex flex-row gap-2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    disabled={checked}
                    onClick={handleCaptureClick}
                  >
                    Enable Capture Mode
                  </Button>
                </Box>
                <FormControl className="flex flex-row gap-4">
                  <InputLabel>Gate</InputLabel>
                  <Select
                    value={gate}
                    onChange={handleSelectedGateChange}
                    label="Gate"
                    className="w-48"
                    disabled={!checked}
                  >
                    {rows.map((element) => (
                      <MenuItem key={element?.id} value={element?.id}>
                        {element?.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <Box className="mt-0 flex w-48 flex-row items-center">
                    <Fab
                      color="primary"
                      className="mr-2"
                      disabled={!checked}
                      onClick={handlePlayClick}
                    >
                      <PlayArrowIcon />
                    </Fab>
                  </Box>
                </FormControl>
              </FormGroup>
            </ListItem>
          </List>
        </CardContent>
      ) : (
        <div className="flex h-screen">
          <div className="m-auto">
            <Spinner />
          </div>
        </div>
      )}
    </Card>
  );
};

export default DroneMode;
