/**
 * Copyright 2023 Google LLC
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import updateDocument from '../../firebase/updateDocument';
import {
  Typography,
  Button,
  DialogActions,
  DialogTitle,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Fab,
  Drawer,
  Box,
  Slider,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { inputOptionSpec } from './inputOptionSpec';
import { GridRowId } from '@mui/x-data-grid';

type Preview =
  | {
      approach: {
        max_speed?: number | null;
        distance_from_target_cm: number;
      };
      id: string;
      imgURL: string;
      label: string;
      name: string;
      steps?:
        | {
            option: string | null;
            value: number | null;
          }[]
        | null;
    }
  | undefined;

type FormProps = {
  id: GridRowId;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  rows: Array<Preview>;
};

const SignMappingActionForm: FunctionComponent<FormProps> = ({ id, setIsOpen, isOpen, rows }) => {
  const getInputOptionSpec = inputOptionSpec();

  type formApproachTypes = {
    distance_from_target_cm: number | null | undefined;
    max_speed?: number | null | undefined;
  };

  type formTransitTypes = {
    option: string | null | undefined;
    value: number | null | undefined;
  };

  type DNDTypes = {
    destination: { index: number } | null;
    source: { index: number };
  };

  const initialValues = {
    option: 'forward',
    value: 0,
  };

  const initialApproachValues = {
    distance_from_target_cm: 100,
    max_speed: 50,
  };

  const [formApproachValues, setFormApproachValues] =
    useState<formApproachTypes>(initialApproachValues);
  const [formTransitValues, setFormTransitValues] = useState<formTransitTypes[]>([initialValues]);
  const [formSignLabel, setFormSignLabel] = useState<string>();

  useEffect(() => {
    const getEditData = rows.find((o) => o?.id === id);
    if (getEditData?.approach && getEditData.steps) {
      if (getEditData.approach.max_speed == 15) {
        getEditData.approach.max_speed = 0;
      }
      setFormApproachValues(getEditData.approach);
      setFormTransitValues(getEditData.steps);
    }
    setFormSignLabel(getEditData?.label);
  }, [id]);

  let addFormFields = () => {
    setFormTransitValues([...formTransitValues, initialValues]);
  };

  let removeFormFields = (i: number) => {
    const newFormTransitValues = [...formTransitValues];
    newFormTransitValues.splice(i, 1);
    setFormTransitValues(newFormTransitValues);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  let handleOptionsChange = (
    index: number,
    element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newFormTransitValues: formTransitTypes[] = [...formTransitValues];
    newFormTransitValues[index] = initialValues;
    newFormTransitValues[index]['option'] = element.target.value;
    setFormTransitValues(newFormTransitValues);
  };

  let handleValueChange = (
    index: number,
    element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newFormTransitValues: formTransitTypes[] = [...formTransitValues];
    newFormTransitValues[index]['value'] = parseInt(element.target.value);
    setFormTransitValues(newFormTransitValues);
  };

  let handleApproachSpeedValueChange = (event: Event) => {
    const target = event.target as HTMLButtonElement;
    const newFormApproachValues: formApproachTypes = { ...formApproachValues };
    newFormApproachValues[target.name as keyof formApproachTypes] = parseInt(target.value);
    setFormApproachValues(newFormApproachValues);
  };

  const handleSave = () => {
    if (formApproachValues.max_speed == 0) {
      formApproachValues.max_speed = 15;
    }

    updateDocument('SignMappings', id, { approach: formApproachValues, steps: formTransitValues });
    setIsOpen(false);
  };

  const getUnit = (element: formTransitTypes) => {
    const inputVariant = getInputOptionSpec.find((option) => option.value === element.option);
    return inputVariant?.unitValue;
  };

  const getVariantValue = (element: formTransitTypes) => {
    const inputVariant = getInputOptionSpec.find((option) => option.value === element.option);
    return inputVariant?.isVariantValue;
  };

  const marks = [
    {
      value: 0,
      label: 'Slow',
    },
    {
      value: 50,
      label: 'Medium',
    },
    {
      value: 100,
      label: 'Fast',
    },
  ];

  const reorder = (
    formTransitActions: formTransitTypes[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(formTransitActions);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DNDTypes) => {
    if (!result.destination) {
      return;
    }

    let movedItems: formTransitTypes[] = reorder(
      formTransitValues,
      result.source.index,
      result.destination.index
    );

    setFormTransitValues(movedItems);
  };

  function valuetext(value: number) {
    return `${value}°C`;
  }

  function valueLabelFormat(value: number) {
    if (value == 15) {
      value = 0;
    }
    return marks[marks.findIndex((mark) => mark.value === value)].label;
  }

  return (
    <Drawer anchor={'right'} open={isOpen} onClose={handleClose}>
      <Box sx={{ minWidth: 360, mt: 8, pl: 2, display: 'inline' }}>
        <DialogTitle variant="h5" sx={{ pl: 0 }} gutterBottom>
          Edit flight plan for {formSignLabel}
        </DialogTitle>
        <Box>
          <form>
            <Typography variant="h6" gutterBottom>
              Approach
            </Typography>
            <Box sx={{ width: 350 }}>
              <Typography variant="caption" gutterBottom>
                Customise the speed of approach and how close to the sign the drone will get before
                stopping.
              </Typography>
            </Box>
            <Box sx={{ width: 300, mb: 2, ml: 1 }}>
              <Typography id="test" sx={{ mt: 3 }} variant="subtitle2" gutterBottom>
                Speed
              </Typography>
              <Slider
                id="max_speed"
                name="max_speed"
                aria-label="Restricted values"
                aria-labelledby="test"
                defaultValue={0}
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                marks={marks}
                step={50}
                min={0}
                max={100}
                sx={{ ml: 1 }}
                onChange={handleApproachSpeedValueChange}
                value={
                  typeof formApproachValues?.max_speed === 'number'
                    ? formApproachValues?.max_speed
                    : 0
                }
              />
            </Box>
            <Typography variant="h6" sx={{ mt: 5 }} gutterBottom>
              Transit
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="add"
                onClick={() => addFormFields()}
                sx={{ ml: 2 }}
              >
                <AddIcon />
                Add Action
              </Fab>
            </Typography>
            <Typography variant="caption" gutterBottom>
              Add actions to get the drone through the sign and onto the next one.
            </Typography>
            <Table sx={{ width: 360 }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                      {formTransitValues.map((element, index) => (
                        <Draggable key={index} draggableId={'q-' + index} index={index}>
                          {(provided) => (
                            <TableRow
                              key={index}
                              sx={{ pt: 1, pb: 1 }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="droppable"
                            >
                              <TableCell sx={{ borderBottom: 'none', pt: 2, pl: 0, pr: 2, pb: 0 }}>
                                <MoreVertIcon />
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: 'none',
                                  pt: 3,
                                  pl: 0,
                                  pr: 1,
                                  pb: 0,
                                  width: 150,
                                }}
                              >
                                <TextField
                                  id="option"
                                  name="option"
                                  sx={{ width: '100%' }}
                                  select
                                  value={element.option || ''}
                                  label="Option"
                                  onChange={(element) => handleOptionsChange(index, element)}
                                >
                                  {getInputOptionSpec.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: 'none',
                                  pt: 3,
                                  pl: 0,
                                  pr: 1,
                                  pb: 0,
                                  width: 200,
                                }}
                              >
                                {formTransitValues[index].option && (
                                  <>
                                    {getVariantValue(element) && (
                                      <TextField
                                        id="value"
                                        name="value"
                                        sx={{ width: '100%' }}
                                        type="number"
                                        value={element.value || ''}
                                        label="Value"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {getUnit(element)}
                                            </InputAdornment>
                                          ),
                                          inputProps: { min: 0, max: 1000 },
                                        }}
                                        onChange={(element) => handleValueChange(index, element)}
                                      />
                                    )}
                                  </>
                                )}
                              </TableCell>
                              <TableCell sx={{ borderBottom: 'none', pt: 2, pl: 0, pr: 0, pb: 0 }}>
                                <IconButton
                                  onClick={() => removeFormFields(index)}
                                  aria-label="delete"
                                  size="large"
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </form>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Box>
    </Drawer>
  );
};

export default SignMappingActionForm;
