/**
 * Copyright 2023 Google LLC
 */

import React, { useEffect, useState } from 'react';
import { CardContent, Divider, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Box from '@mui/system/Box';
import Directional from './Directional';
import Height from './Height';
import Launch from './Launch';
import Descent from './Descent';
import RunContainer from './RunContainer';
import DroneBatteryLevel from './DroneBatteryLevel';
import getDocument from '../../firebase/getDocument';
import Spinner from '../spinner/Spinner';

const Control = () => {
  type CaptureState = {
    enabled: boolean;
    gate: {
      id: string;
      imgURL: string;
      label: string;
      name: string;
    };
    model: string;
    publishTime: string;
    state: string;
  };

  const [isLoading, setIsLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [imageCaptureState, setImageCaptureState] = useState<CaptureState>({
    enabled: false,
    gate: {
      id: '',
      imgURL: '',
      label: '',
      name: '',
    },
    model: '',
    publishTime: '',
    state: '',
  });

  useEffect(() => {
    getDocument(setImageCaptureState, 'ImageCapture', 'captureState');
  }, []);

  useEffect(() => {
    if (imageCaptureState) {
      setChecked(imageCaptureState.enabled);
    }
    setIsLoading(false);
  }, [imageCaptureState]);

  return (
    <Box>
      {checked != undefined && isLoading == false ? (
        <Box>
          <Typography color="text.secondary" variant="h6" className="mb-1 ml-6 mt-4">
            Flight Controls
          </Typography>
          <Divider variant="middle" />
          <Box className="mb-16 mt-4 flex flex-row flex-wrap justify-center gap-2">
            <Box>
              <Card sx={{ width: 350 }}>
                <Typography className="ml-3 mt-3" variant="h6" gutterBottom>
                  Drone State
                </Typography>
                <CardContent sx={{ height: 270 }} className="flex flex-col justify-center gap-4">
                  <RunContainer />
                  <Divider />
                  <DroneBatteryLevel />
                </CardContent>
              </Card>
            </Box>
            <Box className="flex flex-row">
              <Card sx={{ width: 350 }}>
                <Typography className="ml-3 mt-3" variant="h6" gutterBottom>
                  Directional Controls
                </Typography>
                <CardContent sx={{ height: 270 }} className="flex flex-col justify-center gap-2">
                  <Box className="flex flex-row items-center justify-between gap-2">
                    <Height />
                    <Directional />
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box className="flex flex-row">
              <Card sx={{ width: 350 }}>
                <Typography className="ml-3 mt-3" variant="h6" gutterBottom>
                  Launch & Descent Controls
                </Typography>
                <CardContent sx={{ height: 270 }} className="flex flex-col justify-center gap-2">
                  <Box className="flex flex-col justify-center gap-2">
                    <Launch />
                    <Divider />
                    <Descent />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="flex h-screen">
          <Box className="m-auto">
            <Spinner />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Control;
